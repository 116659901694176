<template>
  <div>
    <!-- title -->
    <div class="titles">{{ $t('Financing.Fina') }}</div>
    <!-- 头部 -->
    <div class="headtest">
      <div v-for="(item, index) in headtext" :key="item.id">
        <div class="test">{{ $t(item.t) }}</div>
        <div class="Zero">{{ moneyData[index] }}</div>
      </div>
    </div>
    <!-- 输入框 -->
    <div class="Balances">
      <div>{{ $t('Financing.Balance') }}</div>
      <div>
        <span>{{ $t('Financing.RP') }}</span>
        <input
          type="text"
          v-model="number"
          :placeholder="$t('Financing.Please')"
          @input="principal"
        />
      </div>
    </div>
    <!-- 显示比例 -->
    <div class="Estimated">
      <div>
        {{ $t('Financing.Estimated') }}
        <span>{{ proportion }}</span>
      </div>
    </div>
    <!-- 选中比例 -->
    <div class="income">{{ $t('Financing.Income') }}</div>
    <div class="btn-Select">
      <div
        class="btn-income"
        v-for="(item, index) in proportionData"
        :key="item.id"
        @click="SelectBtn(item, index)"
        v-bind:class="{ is: num === index }"
      >
        <div>{{ $t('Financing.SukuBung') }}</div>
        <div>+{{ interest_rate[index].interest }}%</div>
        <div>
          [{{ $t('Financing.time') }}]&nbsp;{{ item.time
          }}{{ $t('Financing.day') }}
        </div>
      </div>
    </div>
    <!-- 分割线 -->
    <div class="dividing"></div>
    <!-- 底部按钮 -->
    <div class="btn-modular">
      <button class="btn1" @click="TransferOut">
        {{ $t('Financing.TransferOut') }}
      </button>
      <button class="btn2" @click="toChange">
        {{ $t('Financing.ransferIn') }}
      </button>
    </div>
    <Tabbar active="financing" />
  </div>
</template>

<script>
import {
  Tag,
  Col,
  Icon,
  Cell,
  CellGroup,
  Swipe,
  Toast,
  SwipeItem,
  GoodsAction,
  GoodsActionIcon,
  GoodsActionButton,
} from 'vant'

import Tabbar from '@/components/Tabbar'
import { REQUEST_API } from '@/http/api'
import { deciTurnMoney, moneyTurnDeci } from '@/utils/tools' 
export default {
  components: {
    [Tag.name]: Tag,
    [Col.name]: Col,
    [Icon.name]: Icon,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [Swipe.name]: Swipe,
    [SwipeItem.name]: SwipeItem,
    [GoodsAction.name]: GoodsAction,
    [GoodsActionIcon.name]: GoodsActionIcon,
    [GoodsActionButton.name]: GoodsActionButton,
    Tabbar,
  },

  data() {
    return {
      // 显示金额
      moneyData: [],
      // uid
      uid: '',
      // 用户信息
      userData: '',
      // 计算数据
      calculationData: '',
      // 比例
      interest_rate: '',
      // 利率数据
      proportionData: '',
      // 用户信息
      // userData: "",
      // 头部
      headtext: [
        { t: 'Financing.assets' },
        { t: 'Financing.amount' },
        { t: 'Financing.revenue' },
        { t: 'Financing.earnings' },
      ],
      number: '',
      num: 0,
      proportion: 0,
    }
  },
  created() {
    Toast.allowMultiple()
    this.userData = JSON.parse(localStorage.getItem('user-info'))
    this.uid = this.userData[1].data.player.uid
    this.FinancialAPi()
    this.moneyFun()
  },
  // mounted() {
  //   Toast.allowMultiple()
  //   this.userData = JSON.parse(localStorage.getItem('user-info'))
  //   this.uid = this.userData[1].data.player.uid
  //   this.FinancialAPi()
  //   this.moneyFun()
  // },
  methods: {
    // 收入显示API
    moneyFun() {
      var params = {
        cmd: '202445',
        uid: this.uid,
      }
      REQUEST_API(params).then((res) => {
        this.$store.commit('SET_MONEY_INFO', res.data.notifyList[0].data)
        this.moneyData = []
        this.moneyData.push(deciTurnMoney(this.$store.state.user.moneyObj.diamond))
        this.moneyData.push(deciTurnMoney(this.$store.state.user.moneyObj.money))
        this.moneyData.push(deciTurnMoney(this.$store.state.user.moneyObj.reward))
        this.moneyData.push(deciTurnMoney(this.$store.state.user.moneyObj.today_reward))
        // this.moneyData = res.data.notifyList[0].data
      })
    },
    // 转入
    toChange() {
      var params = {
        cmd: '202441',
        money: moneyTurnDeci(this.number),
        uid: this.uid,
        id: this.calculationData.id,
      }
      // 表单验证
      if (this.number === '') {
        Toast(this.$t('Financing.Please'))
        return
      }
      if (!/^[0-9]\d*$/.test(this.number)) {
        Toast(this.$t('Financing.Pleaseint'))
        return
      }
      REQUEST_API(params).then((res) => {
        if (res.data.sc === 0) {
          Toast(this.$t('Financing.Successful'))
          this.number = ''
          this.proportion = 0
          this.principal()
          this.moneyFun()
        } else {
          Toast(this.$t('Financing.Transfer'))
          this.number = ''
          this.proportion = 0
        }
      })
    },
    // 计算比例
    principal() {
      this.proportion = Math.round(
        this.number *
          (this.calculationData.interest_rate / 100000) *
          this.calculationData.time
      )
    },
    // 理财比例API
    FinancialAPi() {
      var params = {
        cmd: '202446',
        uid: this.uid,
      }
      REQUEST_API(params).then((res) => {
        this.calculationData = res.data.notifyList[0].data[0]
        var arr = []
        for (let i = 0; i < res.data.notifyList[0].data.length; i++) {
          var obj = {}
          obj.interest = ~~res.data.notifyList[0].data[i].interest_rate / 1000
          arr.push(obj)
        }
        this.proportionData = res.data.notifyList[0].data
        this.interest_rate = arr
      })
    },
    // 转出记录跳转
    TransferOut() {
      this.$router.push('RansferIn')
    },
    formatPrice() {
      return '¥' + (this.goods.price / 100).toFixed(2)
    },

    onClickCart() {
      this.$router.push('cart')
    },

    sorry() {
      Toast('暂无后续逻辑~')
    },
    // 选中
    SelectBtn(data, index) {
      this.num = index
      this.calculationData = data
      this.principal()
    },
  },
}
</script>

<style lang="less" scoped>
// title顶部文字
.titles {
  width: 100vw;
  height: 2.8rem;
  font-size: 1.37rem;
  color: #434343;
  text-align: center;
  line-height: 2.8rem;
}
// 头部文字
.headtest {
  width: 100vw;
  height: 8rem;
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: space-between;
  flex-wrap: wrap;
  text-align: center;
  border-top: 1px #dddbdb solid;
  border-bottom: 1px #dddbdb solid;
  div {
    width: 48vw;
    height: 42%;
    color: #8c8c8c;
    font-size: 0.7rem;
  }
  .Zero {
    color: #f4484d;
  }
}
// .test {
//   margin-bottom: 0.5rem;
// }
// Balances
.Balances {
  margin: auto;
  width: 90vw;
  height: 3.5rem;
  margin-top: 0.5rem;
  font-size: 1rem;
  border-bottom: 1px solid #dddbdb;
  div {
    color: #000000;
  }
  span {
    color: #f4484d;
    font-size: 0.8rem;
  }
  input {
    width: calc(310rem / 16);
    height: 2rem;
    padding-left: 0.5rem;
    border: 0px;
    font-size: 1rem;
  }
}
// Estimated
.Estimated {
  margin: auto;
  width: 90vw;
  height: 2rem;
  line-height: 2rem;
  font-size: 0.7rem;
  color: #f4484d;
  border-bottom: 1px solid #dddbdb;
  padding: 0.2rem;
  margin-right: 1rem;
  span {
    color: #000000;
    font-size: 1.2rem;
  }
}
// 理财样式
.income {
  width: 90vw;
  height: 1rem;
  font-size: 0.8rem;
  margin: auto;
  margin-top: 1rem;
  color: #777777;
}
.btn-Select {
  width: 90%;
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: space-between;
  flex-wrap: wrap;
  text-align: center;
  margin: auto;
  margin-top: 0.5rem;
}
.btn-income {
  width: 46%;
  height: 4.75rem;
  border: 1px solid #777777;
  margin-top: 0.2rem;
  margin-bottom: 0.5rem;
  color: #f4484d;
  div {
    margin-top: 0.2rem;
    font-size: 0.9rem;
  }
}
.btn-income div:last-child {
  color: black;
}
// 按钮选中样式
.is {
  color: white;
  background-color: #d91d36;
}
// 分割线
.dividing {
  width: 100vw;
  height: 1px;
  background-color: #dddbdb;
  margin-top: 1.5rem;
}
// 底部按钮
.btn-modular {
  width: 90vw;
  text-align: center;
  margin: auto;
  margin-top: 1.5rem;
  margin-bottom: 3rem;
  button {
    width: 45%;
    height: 2rem;
    margin-right: 4%;
    font-size: 0.8rem;
    line-height: 2rem;
    border: 0.5px solid #dddbdb;
    border-radius: 3px;
  }
}
.btn1 {
  background-color: white;
}
.btn2 {
  background-color: #ff6225;
}
</style>
